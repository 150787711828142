import * as React from 'react';
// import * as Rebass from 'rebass';
import Box from '@material-ui/core/Box'
import { Link, navigate } from 'gatsby';
// import styled from '@emotion/styled';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles, Theme } from '@material-ui/core/styles'

import { Layout } from '../../../components/layout';

// const Container = styled(Rebass.Flex)`
//   max-width: 1000px;
//   margin-left: auto;
//   margin-right: auto;
// `

// Container.defaultProps = {
//   flexDirection: 'column',
//   justifyContent: 'center',
//   alignItems: 'center',
//   px: [3, 3, 0],
//   // pt: [3, 0],
//   py: [0, 3, 3],
// }

// const FormTitle = ({
//   as = 'h1',
//   fontFamily = 'Avenir',
//   fontSize = 5,
//   fontWeight = 400,
//   fontColor = '#111',
//   my = 4,
//   ...props
// }) => (
//   <Rebass.Text
//     as={as}
//     sx={{
//       fontFamily,
//       fontSize,
//       fontWeight,
//       fontColor,
//       my,
//     }}
//     {...props}
//   />
// )

// const Message = ({
//   fontFamily = 'Nunito',
//   fontSize = 2,
//   fontWeight = 400,
//   fontColor = '#111',
//   mt = 2,
//   ...props
// }) => (
//   <Rebass.Text
//     sx={{
//       fontFamily,
//       fontSize,
//       fontWeight,
//       fontColor,
//       mt
//     }}
//     {...props}
//   />
// )

// const HomeButton = styled(Rebass.Button)`
//   font-family: 'Avenir';
//   border-color: #3c8aef;
//   background-color: #3c8aef;
//   border-width: 1px;
//   padding: 10px;
//   align-items: center;
//   justify-content: center;
//   border-radius: 5px;
//   box-shadow: 0px 3px 20px rgba(60, 137, 239, 0.5);
//   flex-basis: auto;
//   min-width: 245;
//   max-width: 100%;
//   height: 65px;
//   padding-left: 40px;
//   padding-right: 40px;
//   margin-top: 30px;
//   cursor: pointer;
//   color: #fff;
// `

const BlueButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.getContrastText('#007bff'),
    // boxShadow: 'none',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: 'rgba(60, 137, 239)',
    borderColor: 'rgba(60, 137, 239)',
    boxShadow: '0px 3px 20px rgba(60, 137, 239, 0.5)',
    fontFamily: ['Nunito'].join(','),
    '&:hover': {
      backgroundColor: '#0069d9',
      borderColor: '#0062cc'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#0062cc',
      borderColor: '#005cbf'
    },
    '&:focus': {
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    }
  }
}))(Button)

export default () => {
  React.useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/en')
    }, 15000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <Layout>
      <Container>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          paddingY={4}
          alignItems="center"
        >
          <Typography variant="h3" align="center" gutterBottom>
            Thank you for submitting the form
          </Typography>
          <Typography gutterBottom>You will be automatically redirected, if not, please click the button below.</Typography>
          <Link to="/en">
            <BlueButton>Go Back to Home</BlueButton>
          </Link>
        </Box>
      </Container>
    </Layout>
  )
}
